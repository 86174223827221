* {
  box-sizing: border-box;
}

body {
  flex-direction: column;
  height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
}

header {
  height: 50px;
}

nav {
  max-height: 100%;
  display: flex;
}

nav button {
  flex-basis: 50%;
}

button img {
  max-width: 100%;
  max-height: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
}

main {
  flex-grow: 1;
}

/*# sourceMappingURL=index.10ce2c39.css.map */
