* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

header {
  height: 50px;
}

nav {
  display: flex;
  max-height: 100%;
}

nav button {
  flex-basis: 50%;
}

button img {
  padding-top: 5px;
  padding-bottom: 5px;
  max-width: 100%;
  max-height: 100%;
}

main {
  flex-grow: 1;
}
